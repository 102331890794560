import * as _ from "../../../../node_modules/lodash/index";
import { Util } from "../tools/Util";

declare var CINC: any;

export class SplashLongFormComponentViewModel {
    static longFormComponentId = 'splashLongFormComponent';
    readonly tabId = 'longform-tab';
    readonly popoverId = 'longform-popover';
    readonly closeModalClass = 'close-modal';
    readonly FB_LONG_FORM_PARAM = 'fbprompt';
    readonly LONGFORM_ID = 'longform-form';

    tab: HTMLDivElement;
    popover: any;
    modalClose: HTMLSpanElement;
    body: HTMLElement;
    longform: HTMLFormElement;
    notification: any;
    user: any;

    constructor() {
        // Set Properties
        this.body = <HTMLDivElement>document.body;
        //this.tab = <HTMLDivElement>document.getElementById(this.tabId);
        this.popover = <any>document.getElementById(this.popoverId);
        this.modalClose = <HTMLSpanElement>document.getElementsByClassName(this.closeModalClass)[0];
        this.longform = <HTMLFormElement>document.getElementById(this.LONGFORM_ID);
        this.user = CINC.User;

        // Events
        //this.tab.addEventListener('click', (e: Event) => this.openLongFormModal(event));
        //this.modalClose.addEventListener('click', (e: Event) => this.closeModal(event));
        this.longform.addEventListener('submit', (e: Event) => this.saveLongForm(event));

        // Startup
        this.shouldOpenLongForm();
    }

    saveLongForm(e: Event): void {
        e.preventDefault();
        let inputs = <NodeListOf<HTMLFormElement>>this.longform.querySelectorAll("input, select, textarea");
        let data = this.toJSONString(inputs);
        let isValid = true;

        for (var question in data) {
            if (data[question] == null) {
                isValid = false;
            }
        }

        if (!isValid) {
            //this.notification.Tooltip.Show('Please fill out the fields so we can better assist in your home search');
            alert('Please fill out all the fields so we can better assist you in getting a mortgage');
            return;
        } else {
            const reqData = {surveyAnswers: _.values(data)};
            let http = new XMLHttpRequest();
            http.open('POST', '/api/account/register/extra');
            http.setRequestHeader('content-type', 'application/json')
            http.addEventListener("load", (e) => {});
            http.onload = function () {
                if (http.status == 200) {
                    document.getElementById('longform-popover').style.display = 'none';
                    document.body.style.overflowY = 'auto';
                    CINC.User.ShowMortgageLongForm = false;
                } else if (http.status >= 400) {
                    // error
                }
            };
            http.send(JSON.stringify(reqData));
        }
    }

    toJSONString(formInputs: NodeListOf<HTMLFormElement>): any {
        let obj: any = {};
        
        for (var i = 0; i < formInputs.length; i++) {
            const formInput = <HTMLFormElement>formInputs[i];
            const name = formInput.name;
            let value = null;
            const dataSet = <DOMStringMap>formInput.dataset;
            const type = dataSet['type'];

            switch(type) {
                case 'radio': {
                    let radioInputs = _.filter(formInputs, function(x: HTMLFormElement) { return x.name == name; });
                    const isChecked = _.find(radioInputs, { checked: true });
                    value = typeof isChecked !== 'undefined' ? formInput.value : null;
                    break;
                }
                default: { 
                    value = formInput.value;
                    break;
                }
            }

            if (name) {
                obj[name] = value;
            }
        }

        return obj;
    }

    shouldOpenLongForm(): void {
        if (Util.getURLParamExists(this.FB_LONG_FORM_PARAM) && typeof this.user !== 'undefined' && this.user != null
            && this.user.lenderLead.isLongFormCompleted == false) {
            this.openLongFormModal();
        }
    }

    openLongFormModal(e: Event = null): void {
        this.popover.style.display = 'block';
        this.body.style.overflowY = 'hidden';
    }

    closeModal(e: Event): void {
        this.popover.style.display = 'none'
        this.body.style.overflowY = 'auto';
    }
}

if (document.getElementById(SplashLongFormComponentViewModel.longFormComponentId)) {
    console.log('splash longform component load');
    let SplashLongFormComponentVM = new SplashLongFormComponentViewModel();
}