import { WindowLoadEventHandler } from "../tools/WindowLoadEventHandler";
import { DomainEventBus } from "../events/DomainEventBus";
import { SplashPageViewEvent } from "../events/SplashPageViewEvent";
import { Util } from "../tools/Util";

declare var CINC: any;
declare var PromptForSignup: any;

export class SplashPageController {
    readonly FB_LONG_FORM_PARAM = 'fbprompt';
    readonly FB_SIGNUP_PARAM = 'signup';
    readonly ACTION_BTN_CLASS = 'splash-action';

    Location: Location;
    user: any;
    
    constructor(url: Location) {
        this.Location = url;
        this.user = CINC.User;
    }
    
    openSignup(): void {
        if (this.IsSplashPage()) {
            if (typeof this.user === 'undefined' || this.user == null
                && (!Util.getURLParamExists(this.FB_LONG_FORM_PARAM) && !Util.getURLParamExists(this.FB_SIGNUP_PARAM))) {
                PromptForSignup('', false, '1');
            }
        }
    }

    LogPageView() {
        if (!this.IsSplashPage()) {
            return;
        } else {
            DomainEventBus.raise(new SplashPageViewEvent());
        }
    }

    private IsSplashPage() {
        var path = this.Location.pathname;
        return path.indexOf('/splash') > -1;
    }
}

WindowLoadEventHandler.addLoadEvent(function () {
    let splashPageController = new SplashPageController(window.location);
    splashPageController.LogPageView();
});
