﻿
declare var toastr: any;

export class ListingFavoriteUpdater {

    constructor() {
        toastr.options = {
            "positionClass": "toast-top-full-width"
        };
    }

    public static UpdateFavorite(pdid : string, isCurrentlyFavorite: boolean, element?: HTMLElement, loadFunction? : Function) {
        let url = `/api/organizer/favorite/${pdid}`;
        let requestMethod = isCurrentlyFavorite ? "DELETE" : "POST";
        let favoriteState;
        if (element.id) {
            favoriteState = isCurrentlyFavorite ? "listing-unfavorited-" + element.id : "listing-favorited-" + element.id;
        }
        else {
            favoriteState = isCurrentlyFavorite ? "listing-unfavorited" : "listing-favorited";          
        }
 
        let xhttp: XMLHttpRequest = new XMLHttpRequest();
        xhttp.open(requestMethod, url, true);
        if (element && loadFunction) {
            xhttp.addEventListener("load", () => loadFunction(xhttp, isCurrentlyFavorite, element));
        }
        xhttp.addEventListener("error", () => this.saveFailure())
        xhttp.send();
        if (xhttp.status === 200) {
            this.saveSuccess(isCurrentlyFavorite);
        }
    }

    public static saveSuccess(isCurrentlyFavorite: boolean): void {
        if (isCurrentlyFavorite)
            toastr.success("Your favorite listing was saved successfully!");
        else
            toastr.success("Listing was removed from your favorites.");
    }


    public static saveFailure(): void {
        toastr.error("Sorry, we were unable to save your favorite listing.");
    }
}