import { DomainEventBus } from "../events/DomainEventBus";
import { HomeValuationPageViewEvent } from "../events/HomeValuationPageViewEvent";
import { WindowLoadEventHandler } from "../tools/WindowLoadEventHandler";

declare var CINC: any;

export class HomeValuationPageController {
    Location: Location;

    constructor(url: Location){
        this.Location = url;
    }

    public LogPageView(){
        if (!this.IsHomeValuationPage()){ 
            return;
        }

        if (this.IsLoggedIn()) {
            DomainEventBus.raise(new HomeValuationPageViewEvent());
        }

    }
    

    private IsHomeValuationPage() {
        var path = this.Location.pathname.toLowerCase();
        return path.indexOf('/sellersuite/report') === 0 || path.indexOf('/homevaluereport') === 0;
    }

    private IsLoggedIn() {
        var IsLoggedIn = (typeof CINC !== 'undefined' && CINC.User && CINC.User !== null && CINC.User.userDID !== null);
        return IsLoggedIn;
    }

}

WindowLoadEventHandler.addLoadEvent(function(){
    let homeValuationPageController = new HomeValuationPageController(window.location);
    homeValuationPageController.LogPageView();
});
