﻿import * as _ from "../../../../node_modules/lodash/index";
import { Util } from "../tools/Util";
import { LongformWizardInitClickEvent } from '../events/LongformWizardInitClick';
import { DomainEventBus } from '../events/DomainEventBus';

declare var CINC: any;
declare var $: any;
declare var Modals: any;
declare var Notification: any;

export class LongformWizardComponentViewModel {
    static longformWizardComponentId = 'longformWizardComponent';
    readonly popoverId = 'longform-wizard-popover';
    readonly closeModalClass = 'close-modal';
    readonly LONGFORM_ID = 'longform-wizard-form';

    body: HTMLElement;

    // Modal Elements
    popover: HTMLDivElement;
    modalClose: HTMLSpanElement;
    longform: HTMLFormElement;
    closeWizardBtn: HTMLElement;

    // Form Elements
    previousBtn: HTMLButtonElement;
    nextBtn: HTMLButtonElement;
    step = 1;
    totalSteps: number;
    steps: HTMLCollectionOf<Element>;
    form: HTMLFormElement;
    thankyou: HTMLDivElement;
    isComplete: boolean;

    constructor() {
        // Set Properties
        this.body = <HTMLDivElement>document.body;
        this.longform = <HTMLFormElement>document.getElementById(this.LONGFORM_ID);
        this.popover = <HTMLDivElement>document.getElementById(this.popoverId);
        this.modalClose = <HTMLSpanElement>document.getElementsByClassName(this.closeModalClass)[0];
        var closeBtns = <NodeListOf<HTMLElement>>this.popover.getElementsByClassName('modal-close');

        this.previousBtn = <HTMLButtonElement>document.getElementById('wizard-previous');
        this.nextBtn = <HTMLButtonElement>document.getElementById('wizard-next');
        this.steps = document.getElementsByClassName("reg-step");
        this.totalSteps = this.steps.length;
        this.form = <HTMLFormElement>document.getElementsByName('reg-wizard')[0];
        this.thankyou = <HTMLDivElement>document.getElementsByName('wizard-thankyou')[0];
        

        // Events
        

        for (let i = 0; i < closeBtns.length; i++) {
            closeBtns[i].addEventListener('click', (e: Event) => this.closeModal());
        }
        //this.closeWizardBtn.addEventListener('click', (e: Event) => this.closeModal(e));


        this.previousBtn.addEventListener('click', (e: Event) => this.previousStep());
        this.nextBtn.addEventListener('click', (e: Event) => this.nextStep());

        $('.progress-bar').css('width', this.step / this.totalSteps * 100 + '%')
    }

    previousStep(): void {
        let current = this.step;
        let currentStep = _.find(this.steps, function (x: Element) { return parseInt(x.getAttribute('data-step')) == current; });
        
        let previousStep = this.step - 1;

        if (previousStep > 0) {
            this.step--;
            $('.progress-bar').css('width', this.step / this.totalSteps * 100 + '%')
            $('#current-question').html(this.step);
            _.forEach(this.steps, function (x: Element) { x.setAttribute('style', 'display:none'); });
            let previous = _.find(this.steps, function (x: Element) { return parseInt(x.getAttribute('data-step')) == previousStep; });

            previous.setAttribute('style', 'display:inline-block');
        }
    }

    nextStep(): void {
        let current = this.step;
        let currentStep = _.find(this.steps, function (x: Element) { return parseInt(x.getAttribute('data-step')) == current; });
        let currentInput = _.find(currentStep.children, function (x: Element) { return x.hasAttribute("data-step"); });

        if (this.validateInput(currentInput)) {
            this.step++;
            let nextStep = this.step;

            if (nextStep > this.steps.length) {
                this.saveLongForm();
            } else {
                $('.progress-bar').css('width', this.step / this.totalSteps * 100 + '%')
                $('#current-question').html(this.step);
                _.forEach(this.steps, function (x: Element) { x.setAttribute('style', 'display:none'); });
                let next = _.find(this.steps, function (x: Element) { return parseInt(x.getAttribute('data-step')) == nextStep; });

                next.setAttribute('style', 'display:inline-block');
                $(currentStep).children('.step-validation').addClass('hidden');
            }
        } else {
            $(currentStep).children('.step-validation').removeClass('hidden');
        }

        if (this.step == this.steps.length) {
            this.nextBtn.innerHTML = 'GET PRE-QUALIFIED';
        }
    }

    validateInput(stepInput: any): boolean {
        let isValid = true;
        let inputType = stepInput.getAttribute("data-type");

        switch (inputType) {
            case 'select': {
                isValid = this.validateSelect(stepInput);
                break;
            }
            default: {
                isValid = stepInput.value != "" && stepInput.value != null;
                break;
            }
        }

        return isValid;
    }

    validateSelect(select: any): boolean {
        return select.value != "" && select.value != null;
    }

    closeModal(): void {
        if (!this.isComplete) {
            if (confirm('If you close this window you will lose your progress.')) {
                this.popover.style.display = 'none'
                this.body.style.overflowY = 'auto';
            }
        } else {
            this.popover.style.display = 'none'
            this.body.style.overflowY = 'auto';        
        }
    }

    toJSONString(formInputs: NodeListOf<HTMLFormElement>): any {
        let obj: any = {};

        for (var i = 0; i < formInputs.length; i++) {
            const formInput = <HTMLFormElement>formInputs[i];
            const name = formInput.name;
            let value = null;
            const dataSet = <DOMStringMap>formInput.dataset;
            const type = dataSet['type'];

            switch (type) {
                case 'radio': {
                    let radioInputs = _.filter(formInputs, function (x: HTMLFormElement) { return x.name == name; });
                    const isChecked = _.find(radioInputs, { checked: true });
                    value = typeof isChecked !== 'undefined' ? formInput.value : null;
                    break;
                }
                default: {
                    value = formInput.value;
                    break;
                }
            }

            if (name) {
                obj[name] = value;
            }
        }

        return obj;
    }

    saveLongForm(): void {
        let self = this;
        let inputs = <NodeListOf<HTMLFormElement>>this.longform.querySelectorAll("input, select, textarea");
        let data = this.toJSONString(inputs);
        data.isLongform = true;
        var loadingModal = Modals.Loading("Submitting your answers.").Open();

        const reqData = { surveyAnswers: _.values(data), isLongform: true };
        let http = new XMLHttpRequest();
        http.open('POST', '/api/account/register/extra');
        http.setRequestHeader('content-type', 'application/json')
        http.addEventListener("load", (e) => { });
        http.onload = function () {
            if (http.status == 200) {
                $('.wizard-form').addClass('hidden');
                $('.modal-footer').addClass('hidden');
                $('.wizard-thankyou').removeClass('hidden');
                $('#ProperyDetailsLongformButtonComponent').addClass('hidden');
                $('#lender-banner').addClass('hidden');
                self.isComplete = true;
                loadingModal.Close();
            } else if (http.status >= 400) {
                Notification.Banner.Error("Error saving your answers. Please try again.", "Registration Problem");
                loadingModal.Close();
            }
        };
        http.send(JSON.stringify(reqData));
    }
}

if (document.getElementById(LongformWizardComponentViewModel.longformWizardComponentId)) {
    let LongformWizardComponentVM = new LongformWizardComponentViewModel();
}
