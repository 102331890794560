﻿import { MortgageBannerButtonClickEvent } from '../events/MortgageBannerButtonClickEvent';
import { DomainEventBus } from '../events/DomainEventBus';

declare var CINC: any;

export class MortgageBannerComponentViewModel {
    static componentId = 'lender-banner';
    readonly popoverId = 'longform-wizard-popover';

    banner: HTMLDivElement;
    popover: HTMLDivElement;
    body: HTMLElement;
    bannerButton: HTMLButtonElement;


    constructor() {
        this.body = <HTMLDivElement>document.body;
        this.popover = <HTMLDivElement>document.getElementById(this.popoverId);
        this.banner = <HTMLDivElement>document.getElementById(MortgageBannerComponentViewModel.componentId);
        this.bannerButton = <HTMLButtonElement>document.getElementById('mortgage-banner-button');

        this.bannerButton.addEventListener('click', (e: Event) => this.bannerClick(e));
    }

    bannerClick(e: Event): void {
        e.preventDefault();
        DomainEventBus.raise(new MortgageBannerButtonClickEvent())
        if (typeof CINC.User !== 'undefined' && CINC.User != null && CINC.User.lenderLead.isLongFormCompleted == false) {
            this.openLongFormModal();
        }
    }

    openLongFormModal(e: Event = null): void {
        this.popover.style.display = 'block';
        this.body.style.overflowY = 'hidden';
    }
}

if (document.getElementById(MortgageBannerComponentViewModel.componentId)) {
    let mortgageBannerComponentVM = new MortgageBannerComponentViewModel();
}