﻿import { PolylineManipulation } from "../models/PolylineManipulation";
import { CookieManager } from "../tools/CookieManager";
import { SearchListing } from "../search/SearchListing";
import { QuickDetailsListingCardViewModel } from "../search/QuickDetailsListingCard";
import { ListingMapMarker } from "../search/ListingMapMarker";
import { SearchService } from "../search/SearchService";
import { SearchLocationTagContainer } from "../search/SearchLocationTagContainer";
import { BrowserDetector } from "../tools/BrowserDetector";
import { ApiComponent } from "./ApiComponent";
import { SearchPageBroadcast } from '../search/SearchPageBroadcast';
import { DateTimeUtil } from "../tools/DateTimeUtil";
import { GoogleAnalyticsEventLogger } from '../tools/GoogleAnalyticsEventLogger';

import axios from "axios";
import * as $ from "jquery";
import { SearchListingsDataStore } from "../search/SearchListingsDataStore";
declare var Vue: any;
declare var CINC: any;

class GoogleMapPolygonDefaults {
    static readonly fillColor : string = "#169fad";
    static readonly fillOpacity : number = .2;
    static readonly strokeColor : string = "#169fad";
    static readonly strokeOpacity: .7;
    static readonly strokeWeight: 2;
}
export class SearchMapComponent extends ApiComponent {

    searchMap : HTMLElement;
    markers : Array<ListingMapMarker> = [];
    polygons : Array<any> = [];
    mapHorizontalDelta: number;
    firstBoundsChange : boolean = true;
    isMapInitialized : boolean = false;
    SearchListingCardsVM: SearchListing;
    isMobileViewCurrently: boolean = false;

    isMapExpanded: boolean = false;
    map: any;
    isSearchAsIMove: boolean = false;

    drawButton : HTMLButtonElement = <HTMLButtonElement>document.getElementById("button-draw");
    cancelButton : HTMLButtonElement = <HTMLButtonElement>document.getElementById("button-draw-cancel");
    applyButton : HTMLButtonElement = <HTMLButtonElement>document.getElementById("button-draw-apply");
    clearButton : HTMLButtonElement = <HTMLButtonElement>document.getElementById("button-draw-clear");
    drawingMenu : HTMLElement = <HTMLElement>document.getElementById("drawing-menu");
    drawMenu : HTMLElement = <HTMLElement>document.getElementById("draw-menu");
    saveSearchContainer : HTMLElement = <HTMLElement>document.getElementById("save-search-container");
    searchAsMoveContainer: HTMLElement = <HTMLElement>document.getElementById("search-as-move-container");
    searchAsIMove : HTMLElement = <HTMLElement>document.getElementById("search-as-i-move");
    searchAsIMoveCheckBox : HTMLInputElement = <HTMLInputElement>document.getElementById("cb-searchasimove");
    researchButton : HTMLButtonElement = <HTMLButtonElement>document.getElementById("reseach-here");
    siteData: HTMLElement = <HTMLElement>document.getElementById("search-site-data");

    readonly _selectedPinIndex : number = 1000;
    readonly _hoveredListingPinIndex: number = 1001;
    
    constructor() {
        super();
        if(!this.isBotViewing()){
            this.searchMap = document.getElementById("map-search-results");
            if (!(BrowserDetector.isMobileBrowser() || BrowserDetector.isMobileWidth())) {
                this.initMap();
            } else {
                if (this.searchAsMoveContainer) {
                    this.searchAsMoveContainer.style.display = "none";
                }

                if (this.drawMenu) {
                    this.drawMenu.style.display = "none";
                }

                if (this.saveSearchContainer) {
                    this.saveSearchContainer.style.display = "none";
                }
            }
            this.SearchListingCardsVM = new SearchListing();
            this.initMobileEvents();
            this.initResizeEvent();
        }
        document.addEventListener(SearchPageBroadcast.resetFiltersEventName, () => { this.resetFilters(); });
    }

    initMap() {
        if (this.isMapInitialized) {
            return;
        }
        SearchListingsDataStore.ClearListings();
        this.initGoogleMap();
        this.initDrawControls();
        this.initGoogleMapMarkers();
        this.displayViewedMarkers();
        this.initCustomEventListeners();
        this.initExpandMap();
        this.addPolygonForSavedSearch();
        this.isMapInitialized = true;
       
    }

    addCustomControlsToMap() {
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.drawingMenu);
        if (this.saveSearchContainer) {
            this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.saveSearchContainer);
        }
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.searchAsMoveContainer);
        this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(this.drawMenu);
    }

    removeCustomControlsFromMap() {
        this.map.set('zoomControl', false);
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].clear();
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].clear();
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].clear();
        this.map.controls[google.maps.ControlPosition.RIGHT_TOP].clear();
    }

    addPolygonForSavedSearch() {
        let polygonMaps = document.getElementById("polygonmaps");
        let coordinates = polygonMaps.getElementsByClassName("polygonmap");

        if(coordinates) {
            for(let ind = 0; ind < coordinates.length; ind++){
                let coordinatesInput : HTMLInputElement = <HTMLInputElement>coordinates.item(ind);
                let coordinatesString : string = coordinatesInput.dataset["coordinates"];
                let coordinatesArray : string[] = coordinatesString.split(",");

                let latLngs : google.maps.LatLng[] = new Array();
                coordinatesArray.forEach((coordinate) => {
                    let point : string[] = coordinate.split(":");
                    let latLng = new google.maps.LatLng(parseFloat(point[0]), parseFloat(point[1]));
                    latLngs.push(latLng);
                });

                if(!(latLngs.length === 5 && latLngs[0].lat() === latLngs[1].lat())) //eliminating polygonmaps that are rectangles derived from the map selection
                {
                    let polygon = new google.maps.Polygon({
                        map: this.map,
                        paths: latLngs,
                        fillColor: GoogleMapPolygonDefaults.fillColor,
                        fillOpacity: GoogleMapPolygonDefaults.fillOpacity,
                        strokeColor: GoogleMapPolygonDefaults.strokeColor,
                        strokeOpacity: GoogleMapPolygonDefaults.strokeOpacity,
                        strokeWeight: GoogleMapPolygonDefaults.strokeWeight,
                    });
                    this.polygons.push(polygon);
                }
            }
            this.updateFiltersDataWithPolygonFromDrawing();
        }
    }

    initResizeEvent() {
        window.addEventListener("resize", () => { 
            if (!BrowserDetector.isMobileBrowser()) {
                if (BrowserDetector.isMobileWidth() && this.isMobileViewCurrently) {
                    this.searchMap.style.display = "none";
                    this.initMap();
                    this.map.set('zoomControl', false);
                    this.removeCustomControlsFromMap();
                    this.isMobileViewCurrently = false;
                    this.searchAsMoveContainer.style.display = "none";
                    this.drawMenu.style.display = "none";
                }
                else if (!BrowserDetector.isMobileWidth() && !this.isMobileViewCurrently) {
                    this.searchMap.style.display = "block";
                    this.searchAsMoveContainer.style.display = "block";
                    this.drawMenu.style.display = "block";
                    this.initMap();
                    this.map.set('zoomControl', true);
                    if (this.map.controls[google.maps.ControlPosition.TOP_CENTER].length === 0) {
                        this.addCustomControlsToMap();
                    }
                    this.isMobileViewCurrently = true;
                }
              
            }
        });
    }

    initMobileEvents() {
        if (BrowserDetector.isMobileWidth()) {
            this.searchMap.style.display = "none";
        }
        document.addEventListener(SearchPageBroadcast.displayMobileMapEventName, () => {
            this.initMap();
            this.toggleMapFeatures();
            this.searchAsMoveContainer.style.display = "block";
            this.drawMenu.style.display = "block";
            if (this.saveSearchContainer) {
                this.saveSearchContainer.style.display = "block";
            }
            //we add this vh logic to make the mobile map resize to the toolbar
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            let searchMap = document.querySelector(".search-map");
            if (typeof searchMap !== "undefined" && searchMap !== null)
                searchMap.classList.add('toolbar-resizer');

           //this.removeCustomControlsFromMap();
        });
        document.addEventListener(SearchPageBroadcast.displayMobileListingsEventName, () => {
            if (BrowserDetector.isMobileWidth()) {
                this.toggleDisplay(this.searchMap, "none");
                let searchMap = document.querySelector(".search-map");
                if (typeof searchMap !== "undefined" && searchMap !== null)
                searchMap.classList.remove('toolbar-resizer');
            }
        });
        document.addEventListener(SearchPageBroadcast.hideMobileMapEventName, () => {
            if (BrowserDetector.isMobileWidth()) {
                this.toggleDisplay(this.searchMap, "none");
                let searchMap = document.querySelector(".search-map");
                if (typeof searchMap !== "undefined" && searchMap !== null)
                    searchMap.classList.remove('toolbar-resizer');
            }
        });
        document.addEventListener(SearchPageBroadcast.closeFiltersWhenOnMobileEventName, () => {
            if (BrowserDetector.isMobileWidth()) {
                let searchMap = document.querySelector(".search-map");
                if (typeof searchMap !== "undefined" && searchMap !== null)
                    searchMap.classList.remove('toolbar-resizer');

                let mobileMapListingsButton: HTMLElement = document.getElementById("MobileMapListingsButton");
                let mapHTML = ' Map';
                let listHTML = ' List';
                if (mobileMapListingsButton && mobileMapListingsButton.innerHTML === mapHTML) {
                    SearchPageBroadcast.DisplayMobileListings();
                } else {
                    SearchPageBroadcast.DisplayMobileMap();
                }
                    
            }
        });
    }

    toggleMapFeatures() {
        this.toggleDisplay(this.searchMap);

        if (this.searchMap.style.display !== "none") {
            google.maps.event.trigger(this.map, "resize");
            this.setMapBounds(this.markers);
            window.scroll(0, 0);
        }
    }

    toggleDisplay(element: HTMLElement, display?: string) {
        if (element.style.display === "" || element.style.display === "none" || element.style.display === undefined) {
            element.style.display = display ? display : "block";
        } else {
            element.style.display = "none";
        }
    }

    initGoogleMap() {
        let _This = this;
        this.map = new google.maps.Map(this.searchMap, {
            center: {lat: 33, lng: -81},
            zoom: 14,
            scrollwheel: false,
            draggable: true,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            fullscreenControl: false,
            gestureHandling: 'greedy'
        });

        let searchAsIMoveFunction : any;
        google.maps.event.addListener(this.map, "bounds_changed", function(this: any) {
            let east = this.getBounds().toJSON().east;
            let west = this.getBounds().toJSON().west;
            let horizontalDelta = east - west;
            if(horizontalDelta < 0)
                horizontalDelta = horizontalDelta * -1;

            _This.mapHorizontalDelta = horizontalDelta;
            if(_This.isSearchAsIMove) {
                if(searchAsIMoveFunction) {
                    clearTimeout(searchAsIMoveFunction);
                    searchAsIMoveFunction = null;
                }
                searchAsIMoveFunction = setTimeout(() => { _This.sendMapSearchRequest(); }, 1300);
            }
            else {
                _This.researchButton.style.display = "block";
                _This.searchAsIMove.style.display = "none";
            }
        });

        google.maps.event.addListener(this.map, "click", function () {
            SearchPageBroadcast.CloseQuickDetailsView();
        });

        google.maps.event.addListener(this.map, "drag", function () {
            SearchPageBroadcast.CloseQuickDetailsView();
        });

        google.maps.event.addListener(this.map, "zoom_changed", function () {
            SearchPageBroadcast.CloseQuickDetailsView();
        });
    }

    initDrawControls() {
        this.drawingMenu.style.display = "none";
        this.clearButton.style.display = "none";
        this.addCustomControlsToMap();
        // if (!BrowserDetector.isMobileWidth()) {
        // }

        this.drawButton.addEventListener("click", () => {
            GoogleAnalyticsEventLogger.log('Search Homes', 'map-draw');
            this.handleDrawButtonClick();
        });

        this.applyButton.addEventListener("click", () => {
            this.handleDrawApplyClick();
        });

        this.clearButton.addEventListener("click", () => {
            this.handleDrawClearClick();
        });

        this.cancelButton.addEventListener("click", () => {
            this.handleDrawCancel();
        });

        this.searchAsIMove.addEventListener("click", () => {
            this.handleSearchAsIMove();
        });
        this.researchButton.addEventListener("click", () => {
            GoogleAnalyticsEventLogger.log('Search Homes', 'map_redraw');
            this.researchButton.style.display = "none";
            this.searchAsIMove.style.display = "block";
            this.sendMapSearchRequest();
        })
    }

    initExpandMap() {

        let button = document.getElementsByClassName("btn--expandmap").item(0);
        let searchContainer = document.body;

        button.addEventListener("click", () =>
        {      
            if (this.isMapExpanded) {
                SearchPageBroadcast.ContractMap();
            }
            else {
                SearchPageBroadcast.ExpandMap();
                GoogleAnalyticsEventLogger.log('Search Homes', 'expand_map');
            }
        });

        document.addEventListener(SearchPageBroadcast.expandMapEventName, () => {
            button.classList.add('expanded');
            button.innerHTML = '<i class="icon-double_arrow_right"></i>';
            this.isMapExpanded = true;
        });
        document.addEventListener(SearchPageBroadcast.contractMapEventName, () =>
        {
            button.classList.remove('expanded');
            button.innerHTML = '<i class="icon-double_arrow_left"></i>';
            this.isMapExpanded = false;
        });
        document.addEventListener(SearchPageBroadcast.autocompleteLinkClickedEventName, () => {
            this.disableSearchAsIMove();
        });

        //transitionEnd event handler so that map redraws after expansion
        searchContainer.addEventListener("webkitTransitionEnd", (ev:TransitionEvent) => {
            this.redrawMapOnSizeChange(ev);
        });
        //firefox event 
        searchContainer.addEventListener("mozTransitionEnd", (ev:TransitionEvent) => {
            this.redrawMapOnSizeChange(ev);
        });
    }

    redrawMapOnSizeChange(ev : TransitionEvent) {
        if((ev.srcElement === document.getElementsByClassName("btn--expandmap").item(0) || ev.target === document.getElementsByClassName("btn--expandmap").item(0)) 
            && ev.propertyName === "left") {
                google.maps.event.trigger(this.map, "resize");
                this.setMapBounds(this.markers);
        }
    }

    initCustomEventListeners() {
        document.addEventListener(SearchPageBroadcast.hoveredListingEventName, (ev: CustomEvent) => {
            this.updateHoveredListing(ev.detail["pdid"], ev.detail["isfavorite"]);
        });
        document.addEventListener(SearchPageBroadcast.unhoveredListingEventName, (ev: CustomEvent) => {
            this.updateUnhoveredListing(ev.detail["pdid"]);
        });
        document.addEventListener(SearchPageBroadcast.favoritedListingEventName, (ev: CustomEvent) => {
            this.updateFavoriteListing(ev.detail["pdid"]);
        });
        document.addEventListener(SearchPageBroadcast.favoritedSelectedPropertyEventName, (ev: CustomEvent) => {
            this.updateFavoriteListing(ev.detail["pdid"]);
        });
        document.addEventListener(SearchPageBroadcast.selectedPropertyClosedEventName, (ev: CustomEvent) => {
            this.deselectListingMapMarker(ev.detail["pdid"]);
        });
        document.addEventListener(SearchPageBroadcast.searchResultsUpdateEventName, (ev: CustomEvent) => {
            this.removeMarkersFromMap();
            this.displayPropertiesOnMap(ev.detail["response"].results);
            SearchPageBroadcast.CloseQuickDetailsView();
            if (ev.detail["resetmap"]) {
                this.setMapBounds(this.markers);
            }
        });
        document.addEventListener(SearchPageBroadcast.searchRequestInitiatedEventName, () => {
            this.removeMarkersFromMap();
        });
        document.addEventListener(SearchPageBroadcast.autocompleteLinkClickedEventName, () => {
            this.removePolygonsFromMap();
        });
    }

    initGoogleMapMarkers() {
        let _This: SearchMapComponent = this;
        let properties = SearchListingsDataStore.GetListings();

        if(properties == null || properties.length == 0) {
            properties = SearchListingsDataStore.UpdateListingsFromDom(document.getElementById("search-map-properties"));
        }

        if(properties != null && properties.length > 0) {
            this.markers = [];
            for(let propIndex = 0; propIndex < properties.length; propIndex++){
                let property = properties[propIndex];
                let iconType : string = property.IsFavorite ? "favorite" : "default";
                let listingMapMarker : any = new ListingMapMarker(this.map, property.Lat, property.Lng, iconType, property.Price, property.PDID, property.Address, propIndex);
                if (property.CanShowAddress) {
                    this.markers.push(listingMapMarker);
                }
            }
            if (this.markers.length > 0) {
                this.initMarkerEvents();
                this.setMapBounds(this.markers);
            }
        }

        if (properties.length <= 0 || this.markers.length <= 0) {
            let url = document.URL;
            if(url.indexOf("acl_county") !== -1 || url.indexOf("acl_city") !== -1)
            {
                let urlsplit = url.split("?");
                urlsplit = urlsplit[1].split("&");
                urlsplit.forEach((parameter : any) => {
                    let pair = parameter.split("=");
                    if(pair[0] === "acl_county" || pair[0] === "acl_city") {
                        let address = decodeURIComponent(pair[1]).replace("+", "");
                        this.setMapLocationByAddress(address);
                    }
                });
            }
            else if(this.siteData.dataset["lat"] && this.siteData.dataset["lng"] && this.siteData.dataset["lat"] !== '0' && this.siteData.dataset["lng"] !== '0')
            {
                let lat : number = parseFloat(this.siteData.dataset["lat"]);
                let lng : number = parseFloat(this.siteData.dataset["lng"]);
                this.map.setCenter({ lat: lat, lng: lng });
            }
            else if (this.siteData.dataset["city"] && this.siteData.dataset["state"] && this.siteData.dataset["city"] !== '' && this.siteData.dataset["state"] !== '') {
                let address = `${this.siteData.dataset["city"]}, ${this.siteData.dataset["state"]}`;
                this.setMapLocationByAddress(address);
            }
            else
            {
                var northEastCoords = new google.maps.LatLng(50.477274,-66.612301);
                var southWestCoords = new google.maps.LatLng(29.308659,-114.495116);
                var northAmericaBounds = new google.maps.LatLngBounds(southWestCoords, northEastCoords);
                this.map.fitBounds(northAmericaBounds);
            }
        }
    }

    deselectListingMapMarker(pdid : string) {
        let marker : any = this.markers.filter((mkr : any) => {
            return mkr["id"] === pdid;
        })[0];

        if(marker) {
            let markerIndex : number = this.markers.indexOf(marker);
            let icon : google.maps.Icon = marker.getIcon();
            icon.url = ListingMapMarker._markerIconViewed[marker["iconType"]];
            marker.setIcon(icon);
            this.markers[markerIndex] = marker;
        }
    }

    removeMarkersFromMap() {
        if((this.markers) && this.markers.length > 0)
        {
            this.markers.forEach((marker: any) => {
                marker.setMap(null);
            });
        }
    }

    addMarkersToMap() {
        if((this.markers) && this.markers.length > 0)
        {
            this.markers.forEach((marker: any) => {
                marker.setMap(this.map);
            });
        }
    }

    setMapLocationByAddress(address: string) {
        let geocoder : any = new google.maps.Geocoder();
        geocoder.geocode({
            'address' : address
        }, (results: any, status: any) => {
            if(status === "OK")
            {
                this.map.setCenter(results[0].geometry.location);
            }
        });
    }

    initMarkerEvents() {
        let _This = this;
        this.markers.forEach((marker : any) => {
            google.maps.event.clearListeners(marker, 'click');

            let icon: any = {
                url : ListingMapMarker._markerIconDefault[marker["iconType"]],
                scaledSize: ListingMapMarker._markerScaledSize,
                labelOrigin: new google.maps.Point(ListingMapMarker._labelPlacement["x"], ListingMapMarker._labelPlacement["y"])
            };
            google.maps.event.addListener(marker, 'click', function() {
                _This.markers.forEach((mkr : any, ind :number, arr : any) => {
                    if(mkr.getIcon()["url"] === ListingMapMarker._markerIconSelected[mkr["iconType"]]) {
                        icon["url"] = ListingMapMarker._markerIconDefault[mkr["iconType"]];
                        mkr.setIcon(icon);
                    }
                    mkr.setZIndex(ind);
                });
                icon["url"] = ListingMapMarker._markerIconSelected[marker["iconType"]];
                marker.setIcon(icon);
                marker.setZIndex(_This._selectedPinIndex);
                _This.updateSelectedPropertyDisplay(marker);
                CookieManager.updateViewedPropertiesCookie(marker["id"]);
                _This.displayViewedMarkers();
                GoogleAnalyticsEventLogger.log('Search Homes', 'property_preview_card_open');
            });
        });
    }

    displayViewedMarkers() {
        let cookieName = "viewedproperties"
        let values = CookieManager.getValueFromCookie(cookieName);
        if(values) {
            let valueArray : Array<string> = values.split(',');

            if(valueArray) {
                valueArray.forEach((id: string) => {
                    let viewedMarker : ListingMapMarker = this.markers.filter((marker : ListingMapMarker) => {
                        return marker["id"].toLowerCase() === id.toLowerCase();
                    })[0];
                    if(viewedMarker) {
                        let icon : google.maps.Icon = <google.maps.Icon>viewedMarker.getIcon();
                        let markerIndex = this.markers.indexOf(viewedMarker);
                        if(icon["url"] === ListingMapMarker._markerIconDefault["default"]) {
                            icon["url"] = ListingMapMarker._markerIconViewed["default"];
                            viewedMarker.setIcon(icon);
                        } else if(icon["url"] === ListingMapMarker._markerIconDefault["favorite"]) {
                            icon["url"] = ListingMapMarker._markerIconViewed["favorite"];
                            viewedMarker.setIcon(icon);
                        }
                        this.markers[markerIndex] = viewedMarker;
                    }
                });
            }
        }
    }

    handleSearchAsIMove() {
        if(parseInt(this.searchAsIMove.dataset["active"]) === 0 || !(this.searchAsIMove.dataset["active"])) {
            this.searchAsIMove.dataset["active"] = "1";
        }
        else {
            this.searchAsIMove.dataset["active"] = "0";
        }
        this.isSearchAsIMove = (parseInt(this.searchAsIMove.dataset["active"]) === 1);

        if (this.isSearchAsIMove) {
            this.searchAsIMoveCheckBox.checked = true;
            this.researchButton.style.display = "none";
        }
        else {
            this.searchAsIMoveCheckBox.checked = false;
        }
    }

    handleDrawButtonClick() {
        this.removePolygonsFromMap();
        this.polygons = [];
        this.disableMapScroll();
        this.removeMarkersFromMap();
        this.searchAsMoveContainer.style.display = "none";
        if (this.saveSearchContainer) { this.saveSearchContainer.style.display = "none"; }

        let _This: SearchMapComponent = this;

        this.displayDrawButtons();

        google.maps.event.addListener(this.map, 'mousedown', function(this: any) {

            var polyline = new google.maps.Polyline({ 
                map : this,
                clickable: false,
                strokeColor: "#169fad"
            });

            google.maps.event.addListener(this, 'mousemove', function(e :any) {
                polyline.getPath().push(e.latLng);
            });

            google.maps.event.addListener(this, 'mouseup', function(this: any) {
                let simplifiedLine = PolylineManipulation.SimplifyLine(polyline, _This.mapHorizontalDelta/200);
                if((simplifiedLine) && simplifiedLine.length > 2)
                {
                    polyline.getPath().clear();
                    simplifiedLine.forEach((element : any) => {
                        polyline.getPath().push(element);
                    });
                    polyline.getPath().push(polyline.getPath().getArray()[0]);
                    let polygon = new google.maps.Polygon({
                        map: _This.map,
                        paths: polyline.getPath().getArray(),
                        fillColor: GoogleMapPolygonDefaults.fillColor,
                        fillOpacity: GoogleMapPolygonDefaults.fillOpacity,
                        strokeColor: GoogleMapPolygonDefaults.strokeColor,
                        strokeOpacity: GoogleMapPolygonDefaults.strokeOpacity,
                        strokeWeight: GoogleMapPolygonDefaults.strokeWeight,
                    });
                    polyline.setMap(null);
                    _This.polygons.push(polygon);
                }
                else{
                    polyline.setMap(null);
                }
                google.maps.event.clearListeners(this, 'mousemove');
                google.maps.event.clearListeners(this, 'mouseup');
            });
        });
    }

    handleDrawApplyClick() {
        this.updateFiltersDataWithPolygonFromDrawing();
        SearchLocationTagContainer.RemoveAllLocationTags();
        SearchLocationTagContainer.CreateCustomMapAreaTag();
        this.isSearchAsIMove = false;
        SearchService.SearchRequest(SearchService.StrCrit(), true);
        this.clearMapListenersAndEnableScroll();
        this.hideDrawButtons();
        this.searchAsMoveContainer.style.display = "block";
        if (this.saveSearchContainer) { this.saveSearchContainer.style.display = "block"; }
    }

    handleDrawClearClick() {
        this.removePolygonsFromMap();
        this.clearMapListenersAndEnableScroll();
        this.clearButton.style.display = "none";
    }

    handleDrawCancel() {
        this.hideDrawButtons();
        this.addMarkersToMap();
        this.removePolygonsFromMap();
        this.clearMapListenersAndEnableScroll();
        this.searchAsMoveContainer.style.display = "block";
        if (this.saveSearchContainer) { this.saveSearchContainer.style.display = "block"; }
    }

    displayDrawButtons() {
        this.drawingMenu.style.display = "block";
        this.drawMenu.style.display = "none";
    }

    hideDrawButtons() {
        this.drawingMenu.style.display = "none";
        this.drawMenu.style.display = "block";
    }

    removePolygonsFromMap() {
        this.polygons.forEach((polygon: any) => {
            polygon.setMap(null);
        });
    }

    clearMapListenersAndEnableScroll() {
        google.maps.event.clearListeners(this.map, 'mousedown');
        google.maps.event.clearListeners(this.map, 'mouseup');
        this.enableMapScroll();
    }

    updateFiltersDataWithPolygonFromDrawing () {
        let polygonmap : string = "";
        this.polygons.forEach((polygon: any) => {
            polygon.getPath().getArray().forEach((latlng: any) => {
                polygonmap += `${latlng.lat()} ${latlng.lng()}, `;
            });
            polygonmap = polygonmap.substr(0, polygonmap.length -2) + ":";
        });

        polygonmap = polygonmap.substr(0, polygonmap.length - 1);
        SearchPageBroadcast.ClearPolygonMap();
        SearchPageBroadcast.UpdatePolygonMap(polygonmap);
    }

    displayPropertiesOnMap(results: any) {
        if(results && results.length > 0){
            this.markers = [];
            let ind = 0;
            results.forEach((property : any) => {
                let pdid = property["pdid"];
                let lat = property["latitude"];
                let lng = property["longitude"];
                let price = property["price"];
                let iconType : string = property["isFavorite"] === true ? "favorite" : "default";
                let address : string = property.streetAddress;
                if (property.canShowAddress && lat && lng)
                {
                    let marker : ListingMapMarker = new ListingMapMarker(this.map, lat, lng, iconType, price, pdid, address, ind);
                    ind++;
                    marker["iconType"] = iconType;
                    this.markers.push(marker);
                }
            });

            if (this.markers.length > 0) {
                this.displayViewedMarkers();
                this.initMarkerEvents();
            }
        }
    }

    disableMapScroll() {
        this.map.setOptions({
            draggable: false,
            zoomControl: false,
            scrollWheel: false,
            disableDoubleClickZoom: true,
            draggableCursor: "url(https://png.icons8.com/office/30/000000/edit.png) 0 30, auto"
        });
    }

    enableMapScroll() {
        this.map.setOptions({
            draggable: true,
            zoomControl: true,
            scrollWheel: true,
            disableDoubleClickZoom: false,
            draggableCursor: 'grab'
        });
    }

    updateSelectedPropertyDisplay(marker : any) {
        axios.get(`/api/details/${marker["id"]}`)
            .then(function(this: any, response: any){
                this.handlePropertyDetailsResponse(response);
            }.bind(this));
    }

    showPropertyDetailsError(selectedPropertyDisplayID: string){
        var isMobile = selectedPropertyDisplayID.endsWith('mobile');

        var tsThis = this;

        // set up vue app here
        var vm = new Vue({
            el: selectedPropertyDisplayID,
            template: `
                <div id="${selectedPropertyDisplayID.replace('#', '')}" class="${isMobile ? 'is-displayed-mobile' : 'is-collapsed-mobile'}">
                    <div class="card--property2-expanded">
                        <div class="expanded-content">
                            <div class="text-center text-xlarge text-red"><i class="icon-unworked"></i></div>
                            <h4 class="text-center">Ooops, something went wrong.</h4>
                            <p class="text-center" style="max-width:400px">We’re sorry, looks like we weren't able to grab the listing details. Please try again.</p>
                        </div>
                        <div class="close" id="close-selected-listing">
                            <i class="icon-close"></i>
                        </div>
                    </div>
                </div>`,
            mounted: function(){
                tsThis.handleEvents();
            }
        });
    }

    showPropertyDetailsData(selectedPropertyDisplayID: string, propData: any){
        var browserCreateDT = DateTimeUtil.serverToBrowserTime(propData.createDT);
        var daysBetweenCreateDTAndNow = Math.round((new Date().getTime() - browserCreateDT.getTime()) / (1000*60*60*24));
        propData.daysOnSite = daysBetweenCreateDTAndNow;
        propData.showDaysOnSite = CINC.Site.On_ShowDaysOnSite;
        var isMobile = selectedPropertyDisplayID.endsWith('mobile');

        var tsThis = this;

        // set up vue app here
        var vm = new Vue({
            el: selectedPropertyDisplayID,
            data: propData,
            template: `
            <div id="${selectedPropertyDisplayID.replace('#', '')}" class="${isMobile ? 'is-displayed-mobile' : 'is-collapsed-mobile'}">
                <div class="card--property2-expanded">
                    <div class="grid__flex grid__2col">
                        <div class="card--property2">
                            <a v-bind:href="detailsUrl" class="link-overlay"></a>
                            <div class="property-image-wrapper">
                                <img class="property-image" v-bind:src="photoUrl" />
                            </div>
                            
                            <div class="property-info">
                                <div class="grid__flex--gutter grid__2col no-collapse-mobile no-bottom-margin">
                                    <div class="property-price">{{priceFriendly}}</div>
                                    <div class="property-interactions text-right">
                                        <a class="btn--fav" v-bind:class="{ 'favd': isFavorite }" id="selected-favorite" :data-pdid="pdid"></a>
                                    </div>
                                </div>
                                <div class="grid__flex--gutter grid__2col grid__baseline no-collapse-mobile">
                                    <div class="property-address">
                                        {{fullAddress}}
                                    </div>
                                    <div class="text-right property-bdba">
                                        {{beds}} Bed{{ beds == 1 ? '' : 's' }} | {{baths}} Bath{{ baths == 1 ? '' : 's' }}
                                    </div>
                                </div>

                                <div class="grid__flex--gutter grid__fluidcol grid__centered no-margin-bottom">
                                    <div class="flex2 property-mls" v-if="(providedBy && providedBy.length > 0) || (mlsLogoUrl && mlsLogoUrl.length > 0)">
                                        <img v-if="mlsLogoUrl" v-bind:src="mlsLogoUrl" v-bind:alt="mlsId" style="max-width: 65px;" />
                                        <span v-if="providedBy">{{listingCardProvidedBy}}</span>
                                    </div>
                                    <div class="flex1 text-right">
                                        <div class="property-status active">{{ mlsStatusDisplayName ? mlsStatusDisplayName : mlsStatus }}</div>
                                    </div>
                                </div>
                                <div class="property-address" v-if="mlsId === 'NWISCONSIN'"><p>{{listingType}}</p></div>
                            </div>
                        </div>
                        <div class="expanded-content">
                            <div class="close" id="close-selected-listing">
                                <i class="icon-close"></i>
                            </div>

                            <div class="grid__flex--gutter grid__1of3 no-collapse-mobile quick-details-school" v-for="school in schoolsAPI">
                                <div>
                                    <div class="icon-group school horizontal">
                                        <div v-if="school.rating">
                                            <div class="thumbnail-round xsmall" v-bind:style="{ backgroundColor: school.ratingColor }">{{school.rating}}</div>
                                            <span class="text-small">out of 5</span>
                                        </div>
                                        <div class="no-school-rating-xsmall thumbnail-round xsmall" v-bind:style="{ backgroundColor: school.ratingColor }" v-else>n/a</div>
                                    </div>
                                </div>
                                <div>{{school.name}}</div>
                            </div>
                            <div class="divider small" v-if="schoolsAPI && schoolsAPI.length > 0"></div>

                            <div class="list__1col half">
                                <ul v-if="showDaysOnSite">
                                    <li>Days on Site:</li>
                                    <li>{{daysOnSite}} days</li>
                                </ul>
                                <ul v-if="yearBuilt">
                                    <li>Year Built:</li>
                                    <li>{{yearBuilt}}</li>
                                </ul>
                            </div>

                            <div class="expanded-footer text-right">
                                <a class="btn--default full-width" v-bind:href=detailsUrl target="_blank">View Details</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`,
            mounted: function(){
                tsThis.handleEvents();
            }
        });
    }

    handleEvents(){
        let vm = new QuickDetailsListingCardViewModel();

        if (!BrowserDetector.isMobileBrowser()) {
            SearchPageBroadcast.CloseFilters();
        }
        
        SearchPageBroadcast.MapMarkerClicked();
    }

    handlePropertyDetailsResponse(response: any){
        let selectedPropertyDisplayID: string = '';
        if ($('#search-map-selected-prop').is(":visible")) {
            selectedPropertyDisplayID = '#search-map-selected-prop';
        }
        else {
            selectedPropertyDisplayID = '#search-map-selected-prop-mobile';
        }

        if(response && response.status && response.status === 200){
            this.showPropertyDetailsData(selectedPropertyDisplayID, response.data);
        }
        else{
            this.showPropertyDetailsError(selectedPropertyDisplayID);
        }
    }

    setMapBounds(markers : any) {
        let bounds : any = new google.maps.LatLngBounds();
        for(let markerInd = 0; markerInd < markers.length; markerInd++) {
            bounds.extend(markers[markerInd].getPosition());
        }
        
        if (!this.mapBoundsAreAtMax(bounds)) {
            this.map.fitBounds(bounds);
        }
    }

    mapBoundsAreAtMax(bounds: any) {
        if (!bounds && typeof bounds.getNorthEast != 'function' && typeof bounds.getSouthWest != 'function'){
            return false;
        }

        let northEastLat = bounds.getNorthEast().lat();
        let northEastLng = bounds.getNorthEast().lng();
        let southWestLat = bounds.getSouthWest().lat();
        let southWestLng = bounds.getSouthWest().lng();

        return northEastLat === -1 && southWestLat === 1 && northEastLng === -180 && southWestLng === 180;
    }

    sendMapSearchRequest() {
        SearchPageBroadcast.ClearLocationFilters();
        SearchPageBroadcast.ClearPolygonMap();
        SearchLocationTagContainer.CreateCustomMapAreaTag();
        SearchPageBroadcast.UpdatePolygonMap(this.getMapDimensions());

        this.removePolygonsFromMap();
        SearchService.SearchRequest(SearchService.StrCrit(), true);
    }

    updateHoveredListing(pdid : string, isfavorite : boolean) {
        let marker : google.maps.Marker = this.markers.filter((marker : any) => {
            return marker["id"] === pdid;
        })[0];

        if(marker) {
            let icon : google.maps.Icon = <google.maps.Icon>marker.getIcon();
            let prevIconUrl = icon.url;
    
            if(marker.getZIndex()) {
                CookieManager.createCookie("hoverZIndex", marker.getZIndex().toString(), 1);
            }
            if(!(icon["url"] === ListingMapMarker._markerIconSelected["default"] || icon["url"] === ListingMapMarker._markerIconSelected["favorite"]))
            {
                if(isfavorite) {
                    icon["url"] = ListingMapMarker._markerIconHighlighted["favorite"];
                }
                else {
                    icon["url"] = ListingMapMarker._markerIconHighlighted["default"];
                }
            }
            CookieManager.createCookie("hoveredIconUrl", prevIconUrl, 1);
            marker.setIcon(icon);
            marker.setZIndex(this._hoveredListingPinIndex); 
        }
    }

    updateUnhoveredListing(pdid : string) {
        let marker : google.maps.Marker = this.markers.filter((marker : any) => {
            return marker["id"] === pdid;
        })[0];

        if (marker) {
            let name: string = "hoveredIconUrl";
            let hoveredIconUrl = CookieManager.getCookie(name);
            let iconUrl: string;
            if (typeof (hoveredIconUrl) !== "undefined" && hoveredIconUrl !== null)
            {
                iconUrl = hoveredIconUrl.substr(name.length + 1);
            }
            let zindName: string = "hoverZIndex";
            let zIndex = CookieManager.getCookie(zindName);
            if (typeof (hoveredIconUrl) !== "undefined" && hoveredIconUrl !== null) {
                zIndex = parseInt(zIndex.substr(zindName.length + 1));
            }
            
            let updateIcon: google.maps.Icon = <google.maps.Icon>marker.getIcon();
            if (typeof (iconUrl) !== "undefined" && iconUrl !== null) {
                updateIcon.url = iconUrl;
                marker.setIcon(updateIcon);
            }
            marker.setZIndex(zIndex);
        }
    }

    updateFavoriteListing(pdid: string) {
        let marker : any = this.markers.filter((marker: any) => {
            return marker["id"] === pdid;
        })[0];

        if(marker) {
            marker["iconType"] = marker["iconType"] === "default" ? "favorite" : "default";

            //update displayed icon
            let icon : google.maps.Icon = <google.maps.Icon>marker.getIcon();
            let currentUrl = icon.url;
            let updateUrl : string = ListingMapMarker.getAlternateIcon(currentUrl);   
    
            //update previousIcon cookie for when unhighlighted
            let cookieName = "hoveredIconUrl"
            let prevCookieUrl = CookieManager.getCookie(cookieName).substr(cookieName.length+1);
            let cookieUrl : string = ListingMapMarker.getAlternateIcon(prevCookieUrl);
            CookieManager.createCookie(cookieName, cookieUrl, 1);
    
            //replace marker in marker array for future interactions
            let markerIndex = this.markers.indexOf(marker);
            icon.url = updateUrl;
            marker.setIcon(icon);
    
            this.markers[markerIndex] = marker;
        }
    }

    getMapDimensions(): any {
        if (!this.map) {
            return null;
        }

        let bounds = this.map.getBounds().toJSON();
        let polygonmap: string = `${bounds.north} ${bounds.west}, ${bounds.north} ${bounds.east}, ${bounds.south} ${bounds.east}, ${bounds.south} ${bounds.west}`;
        return polygonmap;
    }

    disableSearchAsIMove() {
        this.isSearchAsIMove = false;
        if (document.getElementById("cb-searchasimove")) {
            let searchAsIMoveCheckBox: HTMLInputElement = <HTMLInputElement>document.getElementById("cb-searchasimove");
            searchAsIMoveCheckBox.checked = false;
        }
    }
    resetFilters() {
        this.removePolygonsFromMap();
    }
}

if(document.getElementById("map-castle")) {
    let searchMapComponent = new SearchMapComponent();
}


