import * as _ from "../../../../node_modules/lodash/index";
import { RegistrationSplashEvent } from '../events/RegistrationSplashEvent';
import { DomainEventBus } from '../events/DomainEventBus';

declare var $: any;
declare var CINC: any;
declare var Modals: any;
declare var Notification: any;
declare var ga: any;
declare var IsEmail: any;

export class InlineRegistrationViewModel {
    static inlineRegistrationComponentId = 'InlineRegistrationComponent';
    readonly NEXT_BTN = 'reg-next';

    nextBtn: HTMLButtonElement;
    step = 1;
    totalSteps: number;
    steps: HTMLCollectionOf<Element>;
    thankYou: HTMLDivElement;
    stepWizard: HTMLDivElement;
    form: HTMLFormElement;

    constructor() {
        this.stepWizard = <HTMLDivElement>document.getElementsByClassName('step-wizard')[0];
        this.thankYou = <HTMLDivElement>document.getElementsByClassName('reg-thankyou')[0];
        this.nextBtn = <HTMLButtonElement>document.getElementById(this.NEXT_BTN);
        this.steps = document.getElementsByClassName("reg-step");
        this.totalSteps = this.steps.length;
        this.form = <HTMLFormElement>document.getElementsByName('reg-wizard')[0];

        this.nextBtn.addEventListener('click', (e: Event) => this.nextStep());
    }

    nextStep(): void {
        let current = this.step;
        let currentStep = _.find(this.steps, function (x: Element) { return parseInt(x.getAttribute('data-step')) == current; });
        let currentInput = _.find(currentStep.children, function (x: Element) { return x.hasAttribute("data-step"); });

        if (this.validateInput(currentInput)) {
            this.step++;
            let nextStep = this.step;

            if (nextStep > this.steps.length) {
                this.completeRegistration();
            } else {
                _.forEach(this.steps, function (x: Element) { x.setAttribute('style', 'display:none'); });
                let next = _.find(this.steps, function (x: Element) { return parseInt(x.getAttribute('data-step')) == nextStep; });

                next.setAttribute('style', 'display:inline-block');
            }
        } else {
            $(currentStep).children('.step-validation').removeClass('hidden');
        }

        if (this.step == this.steps.length) {
            this.nextBtn.innerHTML = 'GET PRE-QUALIFIED';
        }
    }

    validateInput(stepInput: any): boolean {
        let isValid = true;
        let inputType = stepInput.getAttribute("data-type");

        switch (inputType) {
            case 'select': {
                isValid = this.validateSelect(stepInput);
                break;
            }
            case 'email': {
                isValid = this.validateEmail(stepInput);
                break;
            }
            case 'phone': {
                isValid = this.validatePhone(stepInput);
                break;
            }
            case 'name': {
                isValid = this.validateName(stepInput);
                break;
            }
            default: {
                isValid = stepInput.value != "" && stepInput.value != null;
                break;
            }
        }

        return isValid;
    }

    validateSelect(select: any): boolean {
        return select.value != "" && select.value != null;
    }

    validateEmail(email: any): boolean {
        //var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return IsEmail(email.value);
    }

    validatePhone(phone: any): boolean {
        var phoneInputElement = $('form[name="reg-wizard"]').find('[name="Phone"]');
        return CINC.Registration.isValid.phoneInputValue(phoneInputElement, phone.value);
    }

    validateName(nameInput: any): boolean {
        let name = $.trim(nameInput.value || '');
        let fullName = name.split(" ");

        if (fullName.length <= 1)
            return false;

        for (var i = 0; i < fullName.length; i++) {
            if (fullName[i].length === 0) continue;

            fullName[i] = fullName[i].replace(/[^a-zA-Z]+/g, '');
            if (fullName[i].length === 0) {
                return false;
            }
        }

        return true;
    }

    buildRegistrationRequest(): any {
        var formData = $(this.form).serializeObject();
        formData.Password = formData.Phone;
        formData.Timezone = CINC.currentTimezone();
        formData.TimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

        let data = _.pick(formData, ['FirstName', 'Email', 'Phone', 'Password', 'Timezone'])
        return data;
    }

    buildLongformRequestObj(excludes: string[]): any {
        let data = $(this.form).serializeObject();
        let questions: any = {};

        $('.reg-wizard :input').each(function (this: any) {
            let inputName = $(this).attr('name');
            if (!!inputName && _.find(excludes, inputName) !== 'undefined')
                questions[$(this).attr('name')] = true;
        });

        data.SurveyAnswers = Object.keys(data).map(function (itm) { return data[itm]; });

        return data;
    }

    submitLongform(data: any): void {
        CINC.Api.Account('register/extra')
            .post(data)
            .success(function (result: any) {
            })
            .fail(function (f: any) {
            });
    }

    completeRegistration(): void {
        let registerData = this.buildRegistrationRequest();
        let longformData = this.buildLongformRequestObj(['FirstName', 'Email', 'Phone']);
        let submitLongform = this.submitLongform;
        //$.ajax({
        //    url: '/',
        //    type: 'post',
        //    dataType: 'json',
        //    data: $('form#reg-wizard').serialize(),
        //    success: function (data) {
        //        thankYou.style.display = 'display:none';
        //        stepWizard.style.removeProperty("display");
        //    }
        //});


        var loadingModal = Modals.Loading("Signing up now! Give us a second to get you all set up.").Open();

        CINC.Core.CookiesVanilla.Set('registration-state', 'unregistered', 5);
        CINC.Api.Account('register')
            .post(registerData)
            .success(function (result: any) {
                if (result.isSuccess) {
                    CINC.Registration.callbacks.registrationSuccess(result);

                    // Post the answers to longform now...
                    submitLongform(longformData);

                    $('.thankyou-title').html('Thank You, ' + registerData.FirstName + '!');

                    $('.reg-thankyou').show();
                    $('.step-wizard').hide();
                } else {
                    CINC.Core.CookiesVanilla.Set('registration-state', 'registration-error', 5);
                    loadingModal.Close();
                    Notification.Banner.Error(result.description, "Registration Problem");
                }
            })
            .fail(function (f: any) {
                CINC.Core.CookiesVanilla.Set('registration-state', 'registration-error', 5);
                loadingModal.Close();
                Notification.Banner.Error(f.responseText, "Registration Error");
            })
            .always(function () {
                DomainEventBus.raise(new RegistrationSplashEvent())
            });
    }
}

if (document.getElementById(InlineRegistrationViewModel.inlineRegistrationComponentId)) {
    let inlineRegistrationViewModel = new InlineRegistrationViewModel();
}