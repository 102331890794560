﻿import { DomainEventHandler } from './DomainEventHandler';
import { IGoogleEvent } from './DomainEvent';
import { IMixPanelEvent } from './DomainEvent';
import { EventType } from './DomainEvent';
import * as DomainEventRegistry from './DomainEventRegistry';

export class HomeValuationPageViewEvent implements IGoogleEvent, IMixPanelEvent {

    name: string = "adwords-conversion";    
    category: string = "submit";
    label: string = "conversion tracking script written";
    type: EventType = EventType.PageView;
}

@DomainEventRegistry.DomainEventRegistry.register
class HomeValuationPageViewEventHandler extends DomainEventHandler {
    
    domainEventName: string = "HomeValuationPageViewEvent";

    handleDomainEvent<HomeValuationPageViewEvent>(ev: CustomEvent, args: HomeValuationPageViewEvent) : void {
        this.logMixPanelEvent("Google adwords conversion tracking script written");
    }
}